<template>
  <div class="mx-auto max-w-[94.4rem]">
    <safe-html
      tag="p"
      class="text-center text-6xl font-bold leading-5xl text-on-surface-elevation-1"
      :html="getHTMLTitle()"
    />
    <div class="flex justify-center mt-8">
      <s-text as="p" role="text2" class="text-on-surface-elevation-3 relative flex items-center">
        <span class="text-on-surface-elevation-4 mr-4">{{
          $t('studio.gnb.group_myinfo.nickname')
        }}</span>
        <span class="text-on-surface-elevation-2 mx-4">{{ userInfo?.nickname }}</span>
        <button type="button" class="h-20" @click="handleCopy">
          <s-icon size="3xl" icon="ic-v2-community-copy-line" class="text-on-surface-elevation-2" />
        </button>
        <div
          v-if="isCopySuccess"
          class="absolute top-1/2 left-[calc(100%+.4rem)] -translate-y-1/2 inline-flex gap-4 px-[1rem] py-[.6rem] bg-[rgba(72,74,81,.9)] text-neutral-variant-3 rounded-full"
        >
          <s-icon size="xl" icon="ic-v2-control-check-bold-line" />
          <span class="text-xs leading-xs whitespace-nowrap">{{
            $t('studio.toast.link_copied')
          }}</span>
        </div>
      </s-text>
    </div>
    <div class="mt-[3.8rem] flex gap-24">
      <div
        class="flex flex-col w-[46rem] rounded-[4rem] bg-surface-elevation-1 px-[3rem] py-40 text-center shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] group hover:shadow-[0px_8px_20px_0px_rgba(0,51,150,0.16)]"
      >
        <h2 class="text-on-surface-elevation-1 min-h-[11.8rem]">
          <span class="text-lg font-medium leading-lg">{{
            $t('studio.stu_logged_in.pre_grp_join.create_grp_msg1')
          }}</span>
          <strong class="block text-5xl font-bold leading-4xl">{{
            $t('studio.stu_logged_in.pre_grp_join.create_grp_msg2')
          }}</strong>
        </h2>
        <img
          src="@/assets/images/common/login-group-img-01.svg"
          alt="img"
          class="block h-[15rem] w-full object-contain"
        />
        <div class="mt-auto">
          <safe-html
            tag="p"
            class="stds-text text-text2 mt-[1.8rem] text-on-surface-elevation-3"
            :html="$t('studio.stu_logged_in.pre_grp_join.create_grp_msg3')"
          />
          <!--TODO: recheck i18n-->
          <s-button
            variant="primary"
            size="md"
            class="mt-24 min-w-[16rem] hover:bg-[#114EEB]"
            @click="openCreateGroupDialog"
          >
            {{ $t('studio.stu_logged_in.pre_grp_join.btn_create_new_grp') }}
          </s-button>
        </div>
      </div>
      <div
        class="flex flex-col w-[46rem] rounded-[4rem] bg-surface-elevation-1 px-[3rem] py-40 text-center shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] group hover:shadow-[0px_8px_20px_0px_rgba(0,51,150,0.16)]"
      >
        <h2 class="text-on-surface-elevation-1 min-h-[11.8rem]">
          <span class="text-lg font-medium leading-lg">{{
            $t('studio.stu_logged_in.pre_grp_join.grp_join_stt_msg1')
          }}</span>
          <!--TODO: recheck i18n -->

          <strong class="block text-5xl font-bold leading-4xl">{{
            $t('studio.stu_logged_in.pre_grp_join.grp_join_stt_msg2')
          }}</strong>
        </h2>
        <img
          src="@/assets/images/common/login-group-img-02.svg"
          alt="img"
          class="block h-[15rem] w-full object-contain"
        />
        <div class="mt-auto">
          <safe-html
            tag="p"
            class="stds-text text-text2 mt-[1.8rem] text-on-surface-elevation-3"
            :html="$t('studio.stu_logged_in.pre_grp_join.grp_join_stt_msg3')"
          />
          <s-button
            variant="primary"
            size="md"
            class="mt-24 min-w-[16rem] hover:bg-[#114EEB]"
            @click="redirectToMyInfo"
          >
            {{ $t('studio.stu_logged_in.pre_grp_join.btn_chk_grp_join_stt') }}
          </s-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  applyToGroupMembershipByInvitedLinkApi,
  fetchLatestSignupApprovalToGroupApi,
  validationGroupsApi
} from '@/apis/group.api';
import safeHtml from '@/components/common/safe-html.vue';
import CreateGroupDialog from '@/components/group/group-form-dialog.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { GROUP_TYPE } from '@/constants/my-info.const';
import { HOME_PAGE_URL, MY_INFO_PAGE_URL } from '@/constants/url.const';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import { redirectTo } from '@/utils/common.util';
import { handleCopyToClipboard } from '@/utils/string.util';
import { delayTime } from '@/utils/time.util';
import { definePageMeta } from '#imports';

definePageMeta({
  title: 'Create Group',
  layout: 'empty'
});

const { t } = useI18n();

const route = useRoute();
const { invitedId } = route.query;

const userStore = useUserStore();
const { userInfo, selectedGroupId } = storeToRefs(userStore);
const { fetchSimpleMyInfos } = userStore;

const latestApproval = ref<{ groupName: string; groupId: string }>();
const isCopySuccess = ref(false);

const showMessage = async (content: string) => {
  await showAlert({
    content,
    confirmLabel: t('studio.common.popup_case_cf_btn')
  });
};

const getError = async (errorCode: number, groupData?: string) => {
  switch (errorCode) {
    case STATUS_CODE.MEMBER_NOT_FOUND:
    case STATUS_CODE.MEMBER_INVALID_TOKEN:
    case STATUS_CODE.GROUP_INVITATION_LINK_NOT_FOUND:
      showMessage(t('studio.member_manage.member.invite_link_popup_case_4500_01_4804'));
      break;
    case STATUS_CODE.GROUP_INVITATION_LINK_EXPIRED: {
      const message = `${groupData}<br/>${t(
        'studio.stu_logged_in.pre_grp_join.invitation_expired_msg1'
      )}<br/>
        ${t('studio.stu_logged_in.pre_grp_join.invitation_expired_msg2')}`;
      showMessage(message);
      break;
    }
    case STATUS_CODE.GROUP_MEMBER_ALREADY_JOINED:
      await showAlert({
        content: t('studio.member_manage.member.invite_link_popup_case_4806'),
        confirmLabel: t('studio.common.popup_case_cf_btn')
      });
      if (groupData) {
        await fetchSimpleMyInfos(groupData);
        redirectTo(HOME_PAGE_URL, { groupId: selectedGroupId.value });
      }
      break;
    case STATUS_CODE.GROUP_MEMBER_ALREADY_APPLIED:
      showMessage(t('studio.member_manage.member.invite_link_popup_case_4809'));
      break;
    case STATUS_CODE.GROUP_MAX_OWNED:
      showMessage(t('studio.stu_logged_in.pre_grp_join.create_over_10grp_msg'));
      break;
    default:
      showMessage(t(COMMON_ERROR_MESSAGE_KEY));
      break;
  }
};

const openCreateGroupDialog = async () => {
  try {
    await validationGroupsApi();
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;

    getError(errorCode);
    return;
  }
  const result = await showDialog({
    component: shallowRef(CreateGroupDialog),
    props: {
      header: 'Create Popup Dialog'
    },
    severity: 'info'
  });
  if (result === 'createSuccess') {
    await redirectTo(HOME_PAGE_URL, { groupId: selectedGroupId.value });
  }
};

const redirectToMyInfo = () => {
  redirectTo(MY_INFO_PAGE_URL, { query: { tab: GROUP_TYPE.BE_INVITED } });
};

const requestJoinGroup = async (invitationLinkId: string) => {
  try {
    const groupName = await applyToGroupMembershipByInvitedLinkApi(invitationLinkId);
    if (groupName) {
      await showAlert({
        severity: 'info',
        content: `${groupName} <br/>${t(
          'studio.stu_logged_in.pre_grp_join.grp_join_req_done_msg1'
        )}<br/>${t('studio.stu_logged_in.pre_grp_join.grp_join_req_done_msg2')}`,
        confirmLabel: t('studio.common.popup_case_cf_btn')
      });
    }
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.GROUP_MEMBER_ALREADY_JOINED && latestApproval.value) {
      return;
    }
    getError(errorCode, error.data);
  }
};

const showLastJoinedPopup = async (groupName: string, groupId: string) => {
  await showAlert({
    severity: 'info',
    content: `${groupName}<br/>${t(
      'studio.stu_logged_in.pre_grp_join.grp_join_req_accepted_msg'
    )}<br/>${t('studio.stu_logged_in.pre_grp_join.grp_go_to_grp_pg_guide')}`,
    confirmLabel: t('studio.stu_logged_in.pre_grp_join.btn_go_to_grp_pg')
  });

  await fetchSimpleMyInfos(groupId);
  redirectTo(HOME_PAGE_URL, { groupId, external: true });
};

const getHTMLTitle = () => {
  return `${t('studio.stu_logged_in.pre_grp_join.guide')}`;
};

const handleCopy = async () => {
  if (!userInfo.value?.nickname) {
    return;
  }

  handleCopyToClipboard(userInfo.value.nickname);
  isCopySuccess.value = true;
  await delayTime(3000);
  isCopySuccess.value = false;
};

const init = async () => {
  try {
    const result = await fetchLatestSignupApprovalToGroupApi();
    if (result) {
      const { groupName, groupId } = result;
      latestApproval.value = { groupName, groupId };
    }
  } catch (err) {
    // const error = err as ErrorResponse;
    // console.log('err :>> ', error.statusCode);
  }

  if (invitedId) {
    await requestJoinGroup(invitedId as string);
  }

  if (latestApproval.value) {
    showLastJoinedPopup(latestApproval.value.groupName, latestApproval.value.groupId);
  }
};

init();
</script>
